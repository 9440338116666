import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { FaqHeroSection, FaqContentSection } from '../../components/sections';
import { CountryCode } from '../../utils/constants';

const FaqPage: React.FC = () => (
  <Layout countryCode={CountryCode.SG}>
    <SEO title="FAQ" />
    <FaqHeroSection />
    <FaqContentSection />
  </Layout>
);

export default FaqPage;
